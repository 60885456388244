<template>
	<div class="courses">
		<div class="course-list">
			<div class="course-section">
				<div class="title">北美CS求职必修课</div>
				<div class="des">
					北美计算机通识课 + 免费资源，提前了解北美留学求职的一切
				</div>
				<div class="course-row">
					<div v-for="(item, index) in courses" :key="index" class="col" @click="courseClick(item)"  v-if="item.id == 11">
						<div class="box">
							<img :src="item.cover" />
							<div class="brief">
								<div class="b-title">{{ item.brief }}</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="course-section">
				<div class="title">北美CS求职课程</div>
				<div class="des">
					以面试为核心的体系课程，从基础到实战，助你一路拿到offer
				</div>
				<div class="course-row">
					<div v-for="(item, index) in courses" :key="index" class="col" @click="courseClick(item)" v-if="item.id != 7 && item.id != 9 && item.id != 12 && item.id != 11 && item.id != 13">
						<div class="box">
							<img :src="item.cover" />
							<div class="brief">
								<div class="b-title">{{ item.brief }}</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="course-section">
				<div class="title">1v1北美求职旗舰班</div>
				<div class="des">
					所有北美CS求职课程体系，工业级项目，内推监督，零基础到拿到offer
				</div>
				<div class="course-row">
					<div v-for="(item, index) in courses" :key="index" class="col" @click="courseClick(item)" v-if="item.id == 7 || item.id == 12">
						<div class="box">
							<img :src="item.cover" />
							<div class="brief">
								<div class="b-title">{{ item.brief }}</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="course-section">
				<div class="title">1v1私教</div>
				<div class="des">
					针对北美面试为主的私教服务，各种类型模拟面试一应俱全
				</div>
				<div class="course-row">
					<div v-for="(item, index) in mocks" :key="`course${index}`" class="col" @click="mockClick(item)">
						<div class="box">
							<img :src="item.cover" />
							<div class="brief">
								<div class="b-title">{{ item.brief }}</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import {
		getAllClasses
	} from "@/service/course";
	import {
		Toast
	} from "vant";
	import {
		getAllMockCourses
	} from "@/service/mock";
	export default {
		name: "Course",
		data() {
			return {
				courses: [],
				mocks: [],
			};
		},
		mounted() {
			this.getCourses();
		},
		methods: {
			getCourses() {
				getAllClasses()
					.then((res) => {
						if (!res.result.code) {
							this.courses = res.result;
						}
					})
					.catch((err) => {});
				getAllMockCourses().then((res) => {
					if (!res.result.code) {
						this.mocks = res.result;
					}
				});
			},
			courseClick(item) {
				this.$router.push(`/course/${item.id}`);
			},
			mockClick(item) {
				this.$router.push(`/mock/${item.id}`);
			},
		},
	};
</script>
<style scoped lang="scss">
	.course-section{
		margin-bottom: 20px;
	}
	.courses {
		padding-top: 54px;
	}

	.course-row {
		display: flex;
		flex-wrap: wrap;
		margin: 0 -8px;

		.col {
			padding: 8px;
			width: 50%;
			box-sizing: border-box;
		}
	}

	.banner {
		img {
			width: 100%;
			vertical-align: top;
		}
	}

	.course-list {
		width: calc(100% - 24px);
		padding: 12px;
		text-align: center;
	}

	.box {
		width: 100%;
		text-align: left;
		margin: 0 auto;
		box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
		overflow: hidden;
		border-radius: 10px;

		img {
			display: block;
			width: 100%;
		}

		.brief {
			font-size: 12px;
			font-family: Source Han Sans CN-Regular, Source Han Sans CN;
			font-weight: 400;
			padding: 8px;
		}

		.b-title {
			color: rgba(0, 0, 0, 0.6);
			line-height: 20px;
			display: -webkit-box;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 2;
			overflow: hidden;
		}
	}

	.title {
		color: #34495e;
		font-size: 24px;
		font-weight: 500;
		text-align: left;
	}

	.des {
		color: #34495e;
		font-size: 14px;
		text-align: left;
		line-height: 22px;
		opacity: 0.6;
		margin-bottom: 12px;
	}
</style>